import { Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import NavigationHeader from "app/components/NavigationHeader";
import moment from "moment";
import { useEffect, useState } from "react";
import Account from "./Account";
import useApi from "./useApi";

const Settlements = ({partner}: {partner: any}) => {

    const {access_token} = partner;
    const {
        fetchAccounts,
        fetchBalance,
    } = useApi();

    const [p2m_balance, setP2MBalance] = useState(0);
    const [opc_balance, setOPCBalance] = useState(0);
    const [accounts, setAccounts]      = useState<any[]>([]);
    const [date, setDate]              = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading]        = useState(false);

 
    const getAccounts = () => {
        if (access_token) {
            setLoading(true)
            fetchAccounts(date)
                .then(response => setAccounts(response))
                .finally(() => setLoading(false))
        }
    }

    useEffect(getAccounts, [access_token])
    useEffect(() => {
        fetchBalance('P2M').then(res => setP2MBalance(+res.AvailableBalance))
        fetchBalance('OPC').then(res => setOPCBalance(+res.AvailableBalance))
    }, [])

    return <>
        <NavigationHeader>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" fontWeight="bold">Total: {
                                    (p2m_balance + opc_balance).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'PHP',
                                    })
                                }</Typography>
                                <Typography gutterBottom variant="h5" fontWeight="bold">AllBank - OPC: {
                                    opc_balance.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'PHP',
                                    })
                                }</Typography>
                                <Typography gutterBottom variant="h5" fontWeight="bold">AllBank - P2M: {
                                    p2m_balance.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'PHP',
                                    })
                                }</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container>
                                    <Grid item>
                                        <Typography display="inline" variant="h5" fontWeight="bold">Settlements</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <Grid container
                                            spacing    = {2}
                                            alignItems = "center"
                                        >
                                            <Grid item>
                                                <TextField
                                                    variant    = "outlined"
                                                    size       = "small"
                                                    type       = "date"
                                                    value      = {date}
                                                    inputProps = {{max: moment().format('YYYY-MM-DD')}}
                                                    onChange   = {e => setDate(e.target.value)}
                                            /></Grid>
                                            <Grid item>
                                                <Button
                                                    variant = "contained"
                                                    onClick = {getAccounts}
                                                >
                                                    Apply

                                            </Button></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    {
                                        loading? <Grid item><CircularProgress /></Grid>:
                                        accounts.map(account => <Account date={date} account={account}/>)
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </NavigationHeader>
    </>
}

export default Settlements;