import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import momentz from "moment-timezone";

const GCashQRPHRow = ({item, isAdmin, amountFormat, computeAmount}: any) => {
    const dateFormat = (date: string) => (
        date? (
            date.includes('+')?
            momentz.tz(date, 'Asia/Manila'):
            moment(date.replace('Z', ''))
        ).format('YYYY-MM-DDTHH:mm:ss') : '-'
    )
    const fees = (
        'OrionXchange' == item.client || item.merchant? +item.amount*1.6/100:
        'fixed'        == item.fees?.type ? item.fees?.value:
        'percentage'   == item.fees?.type ? +item.amount*item.fees?.value/100:
        item.fees?.value
    )
    const net = +item.amount-fees
    return (
        item.merchant?
        <TableRow hover>
            <TableCell sx={{whiteSpace: 'pre'}}>{`${item.merchant}`}</TableCell>
            <TableCell sx={{whiteSpace: 'pre'}}>{item.reference_number || '-'}</TableCell>
            <TableCell sx={{whiteSpace: 'pre'}}>{item.transaction_id || '-'}</TableCell>
            <TableCell sx={{whiteSpace: 'pre'}}>{item.webhook?.transaction_id || item.reference_id || '-'}</TableCell>
            <TableCell >{item.webhook?.reference_id || '-'}</TableCell>
            <TableCell>{(item.webhook?.instapay_ref || item.instapay_ref)?.slice(-6) || '-'}</TableCell>
            <TableCell>{amountFormat(item.amount) || '-'}</TableCell>
            <TableCell>{amountFormat(net) || '-'}</TableCell>
            <TableCell>{item.webhook?.currency || 'PHP'}</TableCell>
            <TableCell>{item.status || '-'}</TableCell>
            <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.created_at) || '-'}</TableCell>
            <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.paid_at) || '-'}</TableCell>
        </TableRow>:
        isAdmin?
            <TableRow hover>
                <TableCell sx={{whiteSpace: 'pre'}}>{`${item.client}-${item.account}`}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.reference_number || '-'}</TableCell>
                <TableCell>{item.transaction_id || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.webhook?.transaction_id || item.reference_id || '-'}</TableCell>
                <TableCell>{item.webhook?.reference_id || '-'}</TableCell>
                <TableCell>{(item.webhook?.instapay_ref || item.instapay_ref)?.slice(-6) || '-'}</TableCell>
                <TableCell>{amountFormat(item.amount) || '-'}</TableCell>
                <TableCell>{amountFormat(net) || '-'}</TableCell>
                <TableCell>{item.webhook?.currency || 'PHP'}</TableCell>
                <TableCell>{item.status || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.created_at) || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.paid_at) || '-'}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.reference_number || '-'}</TableCell>
                <TableCell>{item.transaction_id || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.webhook?.transaction_id || item.transaction_id  || '-'}</TableCell>
                <TableCell>{item.webhook?.reference_id || '-'}</TableCell>
                <TableCell>{(item.webhook?.instapay_ref || item.instapay_ref)?.slice(-6) || '-'}</TableCell>
                <TableCell>{amountFormat(item.amount) || '-'}</TableCell>
                <TableCell>{amountFormat(net) || '-'}</TableCell>
                <TableCell>{item.webhook?.currency || 'PHP'}</TableCell>
                <TableCell>{item.status || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.created_at) || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.paid_at) || '-'}</TableCell>
            </TableRow>
    )
}

export default GCashQRPHRow;