import { Button, Card, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ACCOUNTS_TOTAL } from "app/urls";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    isPartnerAdmin?: boolean
    partner: any
    intent: 'cashin' | 'cashout'
    goToAccount: (account: string) => any
}

const AccountsTableController = ({isPartnerAdmin, partner, intent}: Props) => {

    const {access_token} = partner;
    const {id, type} = useParams();
    const [accounts, setAccounts] = useState<any>({});
    const [loading, setLoading]   = useState<any>({});
    const [gross, setTotalGross]  = useState(0);
    const [net, setTotalNet]      = useState(0);
    const [start, setStart]       = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]           = useState(moment().format('YYYY-MM-DD'));

    const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})

    const partner_admin = partner.profile.groups.find((value: string) => value != 'partner-admin')

    const getAccounts = () => {
        setLoading(true);
        let query = Object
            .entries({
                start, end, intent,
                tranType: 'C',
                tranDescription: 'P2M',
                partner_admin: isPartnerAdmin? partner_admin: ''
            })
            .map(
                ([key, value]) => `${key}=${value}`
            )
            .join('&')
        fetch(ACCOUNTS_TOTAL({id, type, query}), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
        })
        .then(res => res.json())
        .then(res =>  setAccounts(res || {}))
        .finally(() => setLoading(false));
    }

    const computeTotal = () => {
        let gross = 0, net = 0;
        for (const acc in accounts) {
            gross += +accounts[acc].gross;
            net   += +accounts[acc].net;
        }
        setTotalGross(gross)
        setTotalNet(net)
    }

    useEffect(getAccounts, []);
    useEffect(computeTotal, [accounts]);

    return {
        accounts,
        gross, net,
        start, setStart,
        end, setEnd,
        loading, setLoading,
        apply: getAccounts,
        amountFormat,
    }
}

const AccountsTable = ({isPartnerAdmin, partner, intent, goToAccount}: Props) => {
    const {
        accounts,
        loading,
        gross, net,
        start, setStart,
        end, setEnd,
        apply,
        amountFormat,
    }: any = AccountsTableController({isPartnerAdmin, partner, intent, goToAccount});

    return <>
        <Grid item xs={true}>
            <Card style={styles.tableCard}>
                <Grid container alignItems ="center" alignContent="space-between" direction="row">
                    <Grid item>
                        <Typography variant="h6" fontWeight="bold" whiteSpace="pre" sx={styles.tableTitle}>{{cashin: 'Cash-In', cashout: 'Cash-Out'}[intent]}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6" fontWeight="bold" whiteSpace="pre" sx={styles.tableTitle}>Total Gross: ₱ {amountFormat(gross)}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6" fontWeight="bold" whiteSpace="pre" sx={styles.tableTitle}>Total Net: ₱ {amountFormat(net)}</Typography>
                    </Grid>
                    <Grid item minWidth='440px' display="inline-block">
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant  = "contained"
                                    color    = "primary"
                                    size     = "large"
                                    onClick  = {apply}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Account</TableCell>
                                <TableCell>Gross</TableCell>
                                <TableCell>Net</TableCell>
                                {!isPartnerAdmin && <>
                                    {'cashout' == intent && <>
                                        <TableCell>Balance</TableCell>
                                    </>}
                                    <TableCell>Total</TableCell>
                                    {'cashin' == intent && <>
                                        <TableCell>Transactions per Min</TableCell>
                                        <TableCell>Transactions {"<"} 450</TableCell>
                                        <TableCell>Transactions {">"} 450</TableCell>
                                        <TableCell>Success Rate</TableCell>
                                        <TableCell>Success</TableCell>
                                        <TableCell>Closed</TableCell>
                                        <TableCell>Pending</TableCell>
                                    </>}
                                </>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(loading? []: Object.keys(accounts)).sort().map((key: any) =>
                                <TableRow hover>
                                    <TableCell onClick={() => goToAccount(key)} sx={{":hover": {cursor: 'pointer'}}}>
                                        <Typography color="primary">{key}</Typography>
                                    </TableCell>
                                    <TableCell style={{whiteSpace: 'pre'}}>₱ {amountFormat(+accounts[key].gross)}</TableCell>
                                    <TableCell style={{whiteSpace: 'pre'}}>₱ {amountFormat(+accounts[key].net)}</TableCell>
                                    {!isPartnerAdmin && <>
                                        {'cashout' == intent && <>
                                            <TableCell>₱ {amountFormat(+accounts[key].balance)}</TableCell>
                                        </>}
                                        <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].count}</TableCell>
                                        {'cashin' == intent && <>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].transactions_per_minute}</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].transactions_less_450}</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].transactions_more_450}</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].success_rate.toFixed(2)} %</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].success}</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].closed}</TableCell>
                                            <TableCell style={{whiteSpace: 'pre'}}>{accounts[key].pending}</TableCell>
                                        </>}
                                    </>}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    </>
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        width: '100px',
        margin: 0,
        marginRight: '1rem'
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    }
}

export default AccountsTable;