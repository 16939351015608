import { Button } from "@mui/material";
import LoginForm, { LoginForm as FormData } from "app/components/Login";

interface Props {
    title?: string
    login: (form: FormData) => Promise<any>
    redirectToResetPassword: () => void
}

const Login = ({title, login, redirectToResetPassword}: Props) => {
    return <div style={styles.loginContainer as any}>
        <LoginForm title={title} submit = {login}/>
        <Button
            type = "button"
            variant = "text"
            onClick = {redirectToResetPassword}
        >
            Reset Password
        </Button>
    </div>
}

const styles = {
    loginContainer: {
        textAlign: 'center'
    }
}

export default Login;