import RequestReset from "app/components/RequestReset";
import { Navigate } from "react-router-dom";
import Login from "../Login";
import Finance from "./Finance";
import Dashboard from "./Settlements";
import LoginController from "./controllers/LoginController";
import PageController from "./controllers/PageController";
import ResetPasswordController from "./controllers/ResetPasswordController";

const Settlements = () => {

    // -- Controllers --

    const {type, partner, login} = LoginController();
    const {resetPassword} = ResetPasswordController();
    const {
        page,
        redirectToResetPassword
    } = PageController({partner});
    
    // -- End Of Controllers --

    switch (page) {
        case 'login'        : return <Login login={login} redirectToResetPassword={redirectToResetPassword}/>
        case 'resetPassword': return <RequestReset submit={resetPassword}/>
        case 'settlements'  :
            switch (type) {
                case 'finance': return <Finance partner={partner}/>
                default: return <Dashboard partner={partner}/>
            }
    }
    
    return <Navigate to="/"/>
}

export default Settlements;