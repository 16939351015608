import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import NavigationHeader from "app/components/NavigationHeader";
import { API_URL } from "app/urls";
import moment from "moment";
import { useEffect, useState } from "react";
import { NumberFormatBase, NumberFormatBaseProps, useNumericFormat } from "react-number-format";
import Swal from "sweetalert2";

const Finance = ({partner}: any) => {
    const [accounts, setAccounts] = useState<any[]>();
    const [transactions, setTransactions] = useState<any[]>();
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [start, setStart] = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd] = useState(moment().format('YYYY-MM-DD'));

    const getAccounts = () => {
        setLoadingAccounts(true);
        fetch(
            `${API_URL}/finance/settlements/accounts`,
            {headers: {Authorization: `Bearer ${partner.access_token}`}}
        )
        .then(res => res.json())
        .then(json => setAccounts(json))
        .finally(() => setLoadingAccounts(false))
    }

    const getTransactions = () => {
        setLoadingTransactions(true);
        fetch(
            `${API_URL}/finance/settlements/transactions?start=${start}&end=${end}`,
            {headers: {Authorization: `Bearer ${partner.access_token}`}}
        )
        .then(res => res.json())
        .then(json => setTransactions(json))
        .finally(() => setLoadingTransactions(false))
    }

    const refresh = () => {
        getAccounts()
        getTransactions()
    }

    useEffect(() => {
        if (!accounts) {
            getAccounts()
        }
    }, [accounts])
    useEffect(getTransactions, [])

    return <>
        <NavigationHeader>
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{margin: '1rem 0'}}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container marginBottom="1rem">
                                    <Grid item>
                                        <Typography display="inline" variant="h5" fontWeight="bold">Accounts</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto'}}>
                                        <Grid container
                                            spacing    = {2}
                                            alignItems = "center"
                                        >
                                            <Grid item>
                                                <LoaderButton variant="contained" onClick={() => getAccounts()} loading={loadingAccounts}>
                                                    Refresh
                                                </LoaderButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    {accounts?.map(account => account.settlement && <Account {...account} partner={partner} refresh={refresh}/>)}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{margin: '1rem 0'}}>
                    <Grid item xs={12}>
                        <Card style={styles.tableCard}>
                            <Grid container alignItems = "center" alignContent="space-between" direction="row">
                                <Grid item xs={6}>
                                    <Typography variant="h5" fontWeight="bold" sx={styles.tableTitle}>
                                        Transactions
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container
                                        spacing        = {2}
                                        alignItems     = "center"
                                        justifyContent = "flex-end"
                                    >
                                        <Grid item>
                                            <TextField
                                                id              = "date"
                                                label           = "Start Date"
                                                type            = "date"
                                                value           = {start}
                                                inputProps      = {{max: end}}
                                                InputLabelProps = {{shrink: true}}
                                                onChange        = {(e: any) => setStart(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id              = "date"
                                                label           = "End Date"
                                                type            = "date"
                                                value           = {end}
                                                InputLabelProps = {{shrink: true}}
                                                onChange        = {(e: any) => setEnd(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant = "contained"
                                                color   = "primary"
                                                size    = "large"
                                                onClick = {getTransactions}
                                                disabled = {loadingTransactions}
                                            >
                                                {loadingTransactions? <CircularProgress size="20px" />: 'APPLY'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={styles.tableContainer}>
                                <Table size="small" stickyHeader style={{minWidth: '100%', minHeight: '250px'}}>
                                    <TableHead>
                                        <TableRow sx={{whiteSpace: 'pre'}}>
                                            <TableCell>Account Number</TableCell>
                                            <TableCell>Bank Reference</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Reference</TableCell>
                                            <TableCell>Running Balance</TableCell>
                                            <TableCell>Sender Reference</TableCell>
                                            <TableCell>Source</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{whiteSpace: 'pre'}}>
                                        {transactions?.map(t => <TableRow hover>
                                            <TableCell>{t.accountNumber || "-"}</TableCell>
                                            <TableCell>{t.bankReference || "-"}</TableCell>
                                            <TableCell>{t.ibftStatus || "-"}</TableCell>
                                            <TableCell>{t.reference || "-"}</TableCell>
                                            <TableCell>{t.runningBalance || "-"}</TableCell>
                                            <TableCell>{t.senderRefid || "-"}</TableCell>
                                            <TableCell>{t.source || "-"}</TableCell>
                                            <TableCell>{t.tranAmount || "-"}</TableCell>
                                            <TableCell>{t.tranCode || "-"}</TableCell>
                                            <TableCell>{t.tranDescription || "-"}</TableCell>
                                            <TableCell>{t.tranID || "-"}</TableCell>
                                            <TableCell>{t.tranType || "-"}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </NavigationHeader>
    </>
}

const Account = ({name, id, display_name, balance, settlement, partner, refresh}: any) => {
    const [total, setTotal] = useState(0);
    const [method, setMethod] = useState<string>();
    const [processing, setProcessing] = useState(false);
    const [prompt, setPrompt] = useState(false);
    const [otp, setOtp] = useState('');
    const [verifyingOtp, setVerifyingOtp] = useState(false);

    const updateOTP = (e: any) => {
        if(/^\d*$/.test(e.target.value) && e.target.value.length <= 6) {
            setOtp(e.target.value)
        }
    }

    const sendOTP = () => {
        if (total && method) {
            setProcessing(true);
            fetch(
                `${API_URL}/finance/settlements/otp`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${partner.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id, total, method
                    })
                }
            )
            .then(res => {
                res.status == 200? setPrompt(true): Swal.fire('Failed to send OTP')
            })
            .finally(() => setProcessing(false))
        }
    }

    const transfer = () => {
        if (total && method) {
            setVerifyingOtp(true)
            fetch(
                `${API_URL}/finance/settlements/transfer`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${partner.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({otp})
                }
            )
            .then(res => {
                if(res.status == 200) {
                    setPrompt(false)
                    Swal.fire('Successfully processed transfer')
                    refresh()
                } else {
                    Swal.fire('Failed to process transfer')
                }
            })
            .finally(() => setVerifyingOtp(false))
        }
    }

    return  <>
        <Grid item xs={12} md={6} lg={4} px="1.5rem" borderRight="solid grey 1px">
            <Typography variant="h6" fontWeight="bold">{display_name || name}</Typography>
            <Typography>{settlement.source.name}</Typography>
            <br/>
            <Typography>Current Balance:</Typography>
            <Typography variant="h6" fontWeight="bold">{(+balance).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography>
            <br/>
            {settlement?.last_debit && <>
                <Typography>Last Debit:</Typography>
                <Typography variant="h6" fontWeight="bold">{settlement.last_debit.amount}</Typography>
                <Typography variant="subtitle1">Last transaction: {settlement.last_debit.timestamp}</Typography>
                <Typography variant="subtitle1">via {settlement.last_debit.mode_of_payment}</Typography>
                <Typography variant="subtitle1">By: {settlement.last_debit.debited_by}</Typography>
            </>}
            <br/>
            <Typography>{settlement.source.bank.account_name}</Typography>
            <br/>
            <Typography>Source: {settlement.source.name} ({settlement.source.bank.account_number})</Typography>
            <br/>
            <NumberInput
                value    = {total}
                label    = "Total: ₱"
                onChange = {(e: any) => setTotal(+(e.target.value.replaceAll(',', '')))}
            />
            <br/>
            <Typography><b>Bank:</b> {settlement.recipient.bank.name}</Typography>
            <Typography><b>Account Name:</b> {settlement.recipient.bank.account_name}</Typography>
            <Typography><b>Account Number:</b> {settlement.recipient.bank.account_number}</Typography>
            <br/>
            <FormControl>
                <RadioGroup name="mode_of_payment" onChange={e => setMethod(e.target.value)}>
                    <FormControlLabel value="instapay" style={{margin: '0.5rem 0'}} control={<Radio checked={method == 'instapay'} />} label={<>
                        <Typography fontWeight="bold">
                            Instapay: +{(Math.ceil(total / 50000) * 4.5).toLocaleString(undefined, {minimumFractionDigits: 2})}
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1">
                            - {Math.ceil(total / 50000)} transactions x 4.50
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1">
                            - Near real-time transfers
                        </Typography>
                    </>}/>
                    <FormControlLabel value="pesonet" style={{margin: '0.5rem 0'}} control={<Radio checked={method == 'pesonet'} />} label={<>
                        <Typography fontWeight="bold">
                            Pesonet: Free
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1">
                            - Before 9 AM: Processed by 2 PM on the same day
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1">
                            - Before 3 PM: Processed by 6 PM on the same day
                        </Typography>
                        <Typography fontSize={12} variant="subtitle1">
                            - After 3 PM or on a non-business day: Processed on the next business day
                        </Typography>
                    </>} />
                </RadioGroup>
            </FormControl>
            <Box>
                <LoaderButton display="inline-block" onClick={sendOTP} disabled={!total || !method || processing} loading={processing}>
                    Transfer
                </LoaderButton>
                <Dialog
                    open={prompt}
                    onClose={() => setPrompt(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Verification
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{margin: '1rem 0'}}>
                            Enter OTP sent to your mobile number
                        </DialogContentText>
                        <TextField fullWidth variant="outlined" onChange={updateOTP} inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxlenght: 6}} value={otp}/>
                    </DialogContent>
                    <DialogActions>
                        <LoaderButton variant="default" onClick={() => setPrompt(false)} disabled={verifyingOtp}>Cancel</LoaderButton>
                        <LoaderButton variant="default" onClick={transfer} autoFocus loading={verifyingOtp}>
                            Submit
                        </LoaderButton>
                    </DialogActions>
                </Dialog>
            </Box>
        </Grid>
    </>
}

const LoaderButton = (props: any) => (
    <Button
        variant  = {props.variant || "contained"}
        sx       = {{display: props.display || 'block', margin: '1rem 0'}}
        disabled = {props.disabled || props.loading}
        {...props}
    >
        {
            props.loading?
            <CircularProgress size={20}/>:
            props.children
        }
    </Button>
)

const Input = (props: any) => <TextField variant="outlined" style={{margin: '0 0.5rem'}} {...props}/>

const NumberInput = ({value, onChange, label, helper, disabled}: any) => {
    const numericFormat: NumberFormatBaseProps = useNumericFormat({
        thousandSeparator: ',',
        decimalSeparator : '.',
    })
    return (
        <FormGroup>
            <FormControlLabel
                control = {
                    <NumberFormatBase
                        {...numericFormat}
                        value       = {value}
                        disabled    = {disabled}
                        customInput = {Input}
                        onChange={onChange}
                    />
                }
                label           = {label}
                labelPlacement  = "start"
                componentsProps = {{typography: {fontWeight: "bold"}}}
                style           = {{alignSelf: 'start', margin: 0}}
                
            />
            {helper && <FormHelperText color="GrayText">{helper}</FormHelperText>}
        </FormGroup>
    )
}

const styles = {
    tableCard: {
        minHeight: '100px',
        padding: '1rem'
    },
    tableContainer: {
        margin         : '1rem 0',
        height         : '100%',
        maxHeight      : 440,
        backgroundColor: "#fff"
    },
    tableTitle: {
        margin: 0
    },
    tableSubtitle: {
        margin    : 0,
        fontWeight: 'normal'
    },
    searchField: {
        marginRight: '1rem',
        marginLeft: '1rem'
    },
    downloadButton: {
        margin: '0.5rem'
    }
}

export default Finance;