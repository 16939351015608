import { TableCell, TableRow } from "@mui/material";

const AllBankSOAColumns = () => {
    return <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Status</TableCell>
    </TableRow>
}

export default AllBankSOAColumns;