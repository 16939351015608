import { TableCell, TableRow } from "@mui/material";

const AllBankSOARow = ({item, amountFormat}: any) => {
    return <TableRow hover>
        <TableCell>{item.tranID}</TableCell>
        <TableCell>{item.tranDate}</TableCell>
        <TableCell>{amountFormat(item.tranAmount || 0)}</TableCell>
        <TableCell>{item.tranType}</TableCell>
        <TableCell>{item.ibftStatus || ''}</TableCell>
    </TableRow>
}

export default AllBankSOARow;